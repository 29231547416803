import React, { useState } from 'react'

import {
    Box,
    Center,
    CloseButton,
    Editable,
    EditableInput,
    EditablePreview,
    Flex,
    Heading,
    HStack,
    Stack,
    Tag,
    TagLabel,
    Text,
} from '@chakra-ui/react'
import { MinusIcon } from '@chakra-ui/icons'

import CardButtons from './CardButtons'

import { baseUrl } from '../env'
import { confirmableCardColors } from '../styles'
import { BareIncentive, GenericTextModel } from '../types/models'

const markCompleted = async function(card: GenericTextModel, modelName: string, updateCards: () => void ) {
    await fetch(`${baseUrl}/${modelName}/${card.id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ completedAt: new Date().toISOString()  })
    })
    updateCards()
}

function GenericCard({ cardData, modelName, fetchCards, refreshCards }: { cardData: GenericTextModel, modelName: string, fetchCards: () => void, refreshCards: () => void}) {
    const [isConfirmable, setIsConfirmable] = React.useState(false)
    const [isConfirmed, setIsConfirmed] = React.useState(false)
    const [deletionIsConfirmable, setDeletionIsConfirmable] = useState(false)
    const [tagEditMode, setTagEditMode] = useState(false)

    const { id, name, description } = cardData
    const lsName = `collapsed${modelName.charAt(0).toUpperCase()}${modelName.slice(1)}`

    const handleClick = () => {
        if (isConfirmable) {
            setIsConfirmable(false)
            setIsConfirmed(true)
            markCompleted(cardData, modelName, fetchCards)
            // This shouldn't do anything visible if the completion succeeded
            setTimeout(() => setIsConfirmed(false), 10000)
        } else {
            setIsConfirmable(true)
            setTimeout(() => setIsConfirmable(false), 2000)
        }
    }

    interface Style {
        dayColor: string,
        bg: string,
        descriptionColor: string
    }
    let style : Style
    if (isConfirmed) { // confirmed (but page hasn't refreshed yet)
        style = confirmableCardColors['confirmed']
    } else if (isConfirmable) { // pending second-tap confirmation
        style = confirmableCardColors['confirmable']
    } else {
        style = confirmableCardColors['pending']
    }

    const tagRow = <HStack width='full' onClick={e => { e.stopPropagation(); setTagEditMode(true) }}>
        {cardData.tags.map(tag => (
        <Tag
        size='sm'
        key={tag}
        >
        <TagLabel>{tag}</TagLabel>
        </Tag>
    ))}
    </HStack>

    return <Center py={3}>
        <Box
        maxW={'445px'}
        w={'full'}
        bg={style.bg}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}
        onClick={handleClick}
        >
        <Flex justifyContent='space-between' w='full' onClick={e => e.stopPropagation()}>
            <MinusIcon onClick={() => {
                // Toggle inclusion in collapsedCards
                const collapsedCards = JSON.parse(localStorage.getItem(lsName) || '[]')
                localStorage.setItem(lsName, JSON.stringify(
                    collapsedCards.includes(id) ?
                    collapsedCards.filter((c: string) => c !== id) :
                    [...collapsedCards, id]
                ))
                refreshCards()
            }}/>
            <CloseButton
                color={deletionIsConfirmable ? 'red' : 'black'}
                onClick={async () => {
                if (deletionIsConfirmable) {
                    await fetch(`${baseUrl}/${modelName}/${id}`, {
                        method: 'DELETE'
                    })
                    fetchCards()
                } else {
                    setDeletionIsConfirmable(true)
                    setTimeout(() => setDeletionIsConfirmable(false), 2000)
                }
            }}/>
        </Flex>
        <Stack>
            <Heading
            color={style.dayColor}
            fontSize={'2xl'}
            fontFamily={'body'}
            >
                {name}
            </Heading>
            <Text color={style.descriptionColor}>
                {(description || '').padEnd(40, String.fromCharCode(8194)).slice(0, 100)}
            </Text>
            { tagEditMode ? null : !cardData.tags.length ?
              <Text
                color={style.descriptionColor}
                onClick={e => { e.stopPropagation(); setTagEditMode(true) }}
              >
                No tags
              </Text> : tagRow
            }
            {
              !tagEditMode ? null :
              <Editable
                defaultValue={cardData.tags.join(', ')}
                placeholder='No tags'
                onSubmit={async s => {
                  const tags = s.split(', ').map(t => t.trim()).filter(t => t)
                  cardData.tags = tags
                  await fetch(`${baseUrl}/${modelName}/${cardData.id}`, {
                    method: 'PATCH',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ tags })
                  })
                  fetchCards()
                  setTagEditMode(false)
                }}
                onClick={e => e.stopPropagation()}
              >
                <EditablePreview />
                <EditableInput />
              </Editable>
            }
            {
                modelName === 'leisureActivities' && (cardData as GenericTextModel & { incentives: BareIncentive[] }).incentives.length ?
                <HStack>
                {(cardData as GenericTextModel & { incentives: BareIncentive[] }).incentives
                .map(incentive => {
                    const typeNameMap = {
                        'LeisureActivityStart': 'Start',
                        'LeisureActivityContinue': 'Continue',
                    } as const
                    const typeName = typeNameMap[incentive.type as keyof typeof typeNameMap]
                    return (
                    <Tag key={incentive.id} colorScheme='cyan'>
                        <TagLabel>{`${typeName}: ${incentive.quantity}`}</TagLabel>
                    </Tag>
                    )
                })}
                </HStack> : null
            }
            { modelName === 'leisureActivities' ? <CardButtons modelType='leisureActivity' model={cardData} updateStack={fetchCards} /> : null }
        </Stack>
        </Box>
    </Center>
}

export default GenericCard