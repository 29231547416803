import { useEffect, useState } from 'react'
import { Box, Text, VStack, HStack, Badge } from '@chakra-ui/react'

import { baseUrl } from '../env'
import dayjs from 'dayjs'

interface Incentive {
    type: string
    task?: { name: string }
    tactic?: { name: string }
    goal?: { name: string }
    leisureActivity?: { name: string }
}

interface PointChange {
    id: string
    quantity: number
    reason?: string
    createdAt: string
    incentive?: Incentive
}

interface PointChangesProps {
    pointChanges: PointChange[]
}

export default function PointChanges() {
    const [pointChanges, setPointChanges] = useState<PointChange[]>([])

    useEffect(() => {
        fetch(`${baseUrl}/pointChanges`)
            .then(response => response.json())
            .then(data => setPointChanges(data))
    }, [])

    return (
        <VStack spacing={4} align="stretch">
            {pointChanges
                .map(({ id, quantity, reason, incentive, createdAt }) => {
                    const color = quantity > 0 ? 'green' : 'red'
                    return (
                    <Box key={id} p={1} borderWidth="1px" borderRadius="lg">
                        <HStack justify="left" spacing={4}>
                            <Text fontWeight="bold">{`${quantity > 0 ? '+' : ''}${quantity}`}</Text>
                            { incentive && <Badge colorScheme={color}>{incentive.type}</Badge> }
                            <Text>{dayjs(createdAt).format('dddd M/DD, hh:mm A')}</Text>
                        </HStack>
                        {reason && <Text>Reason: {reason}</Text>}
                        {incentive && (
                            <Box mt={2}>
                                {incentive.task && <Text>Task: {incentive.task.name}</Text>}
                                {incentive.tactic && <Text>Tactic: {incentive.tactic.name}</Text>}
                                {incentive.goal && <Text>Goal: {incentive.goal.name}</Text>}
                                {incentive.leisureActivity && <Text>Leisure Activity: {incentive.leisureActivity.name}</Text>}
                            </Box>
                        )}
                    </Box>
                )})}
        </VStack>
    )
}