import { useState } from 'react'

import _ from 'lodash'
import {
    Button,
    Flex,
} from '@chakra-ui/react'

import { baseUrl } from '../env'

import { LeisureActivity, TaskOccurrence } from '../types/models'

const makeHandler = (actionName: string) => {
    const fn = async function(modelType: 'taskOccurrence' | 'leisureActivity', model: TaskOccurrence | LeisureActivity, updateStack: () => void) {
        const path = modelType === 'taskOccurrence' ?
            `${baseUrl}/tasks/${(model as TaskOccurrence).task.id}/occurrences/${model.id}/${actionName}` :
            `${baseUrl}/leisureActivities/${model.id}/${actionName}`
        await fetch(path, { method: 'POST'})
        await updateStack()
    }

    return _.debounce(fn, 2000, { leading: true, trailing: false })
}

const handlers = {
    start: makeHandler('start'),
    continue: makeHandler('continue'),
    complete: makeHandler('complete')
}

type ButtonState = 'normal' | 'confirmable' | 'confirmed' | 'error' | 'disabled'
const initialButtonStates = {
    start: 'normal' as ButtonState,
    continue: 'normal' as ButtonState,
    complete: 'normal' as ButtonState
}
type ButtonName = keyof typeof initialButtonStates

export default function CardButtons({
  modelType,
  model,
  updateStack
}:
  {
    modelType: 'taskOccurrence' | 'leisureActivity',
    model: TaskOccurrence | LeisureActivity,
    updateStack: () => void
  }) {
    const [buttonStates, setButtonStates] = useState(initialButtonStates)

    const handleClick = (buttonName: ButtonName) => {
        const buttonState = buttonStates[buttonName]
        if (buttonState === 'confirmable') {
          setButtonStates({ ...initialButtonStates, [buttonName]: 'confirmed' })
          handlers[buttonName](modelType, model, updateStack)
          setTimeout(() => setButtonStates({ ...initialButtonStates, [buttonName]: 'normal' }), 5000)
        } else {
          setButtonStates({ ...initialButtonStates, [buttonName]: 'confirmable' })
          setTimeout(() => setButtonStates({ ...initialButtonStates, [buttonName]: 'normal' }), 2000)
        }  
      }

    const confirmableColor = 'yellow.500'
    const confirmedColor = 'green.500'
    const defaultButtonColors = {
        start: 'blue.300',
        continue: 'blue.200',
        complete: '#2222ff'
    }
    const getButtonColor = (buttonName: ButtonName) => {
        const buttonState = buttonStates[buttonName]
        if (buttonState === 'confirmable') {
            return confirmableColor
        } else if (buttonState === 'confirmed') {
            return confirmedColor
        } else {
            return defaultButtonColors[buttonName]
        }
    }

    const startButton = <Button
        onClick={(event) => { event.stopPropagation(); handleClick('start') }}
        backgroundColor={getButtonColor('start')}
        style={{
            color: 'black',
            zIndex: 1000,
        }}
    >
        Start
    </Button>

    const continueButton = <Button
        onClick={(event) => { event.stopPropagation(); handleClick('continue') }}
        backgroundColor={getButtonColor('continue')}
        style={{
          color: 'black',
          zIndex: 1000,
        }}
    >
        Continue
    </Button>

    const completeButton = <Button
        onClick={(event) => { event.stopPropagation(); handleClick('complete') }}
        backgroundColor={getButtonColor('complete')}
        style={{
          color: 'white',
          zIndex: 1000,
        }}
    >
      Complete
    </Button>

    return <Flex justifyContent='space-between' flexDirection='row-reverse'>
        {completeButton}
        {continueButton}
        {startButton}
    </Flex>
}
